var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-card', {
    staticClass: "border mb-0 mt-1",
    staticStyle: {
      "max-height": "70vh"
    },
    attrs: {
      "header-tag": "header",
      "border-variant": "info",
      "header-bg-variant": "light-info",
      "header-class": "p-1",
      "no-body": ""
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-between w-100"
        }, [_c('h5', {
          staticClass: "m-0 text-uppercase font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.$t('agency.employees.title')) + " ")]), _vm.isRoleF2 ? _c('div', {
          staticClass: "d-flex-center"
        }, [_c('b-form-checkbox', {
          staticClass: "mr-0 mt-50 custom-control-success",
          staticStyle: {
            "top": "-4px"
          },
          attrs: {
            "id": "toggle-send-employee-mail-airline",
            "checked": _vm.enableSendEmployeeMailAirline,
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          nativeOn: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.toggleSendEmployeeMailAirline($event);
            }
          }
        }), _c('label', {
          staticClass: "font-weight-bolder text-dark m-0 font-medium-1",
          attrs: {
            "for": "toggle-send-employee-mail-airline"
          }
        }, [_vm._v(" Cho phép nhân viên nhận mail hãng ")])], 1) : _vm._e()])];
      },
      proxy: true
    }])
  }, [_c('b-table', {
    ref: "refEmployeeListTable",
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "55vh"
    },
    attrs: {
      "sticky-header": true,
      "items": _vm.agenciesData.employees,
      "responsive": "",
      "fields": _vm.tableEmployees,
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "sort-desc": _vm.isSortDirDesc,
      "sort-by": _vm.sortBy,
      "no-border-collapse": "",
      "small": ""
    },
    on: {
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableEmployees, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.label,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("agency.employees.columns.".concat(data.label))) + " ")])];
        }
      };
    }), {
      key: "cell(no)",
      fn: function fn(data) {
        return [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(data.index + 1))])];
      }
    }, {
      key: "cell(employeeCode)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('b-link', {
          staticClass: "font-weight-bold d-block text-nowrap text-info",
          attrs: {
            "disabled": !_vm.canAccess('employee.detailEmployee'),
            "to": {
              name: 'apps-employees-edit',
              params: {
                id: data.item.id
              }
            }
          }
        }, [_c('span', {
          staticClass: "align-text-top text-capitalize font-weight-bold"
        }, [_vm._v(" " + _vm._s(data.item.employeeCode) + " ")])])], 1)];
      }
    }, {
      key: "cell(account)",
      fn: function fn(data) {
        return [_c('h5', {
          staticClass: "font-weight-bold align-text-top mb-0"
        }, [_c('feather-icon', {
          attrs: {
            "size": "14",
            "icon": "UserIcon"
          }
        }), _vm._v(" " + _vm._s(data.item.username || 'N/A') + " ")], 1), _c('div', [_c('small', {
          staticClass: "align-items-center d-flex flex-nowrap"
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "size": "14",
            "icon": "MailIcon"
          }
        }), _c('span', [_vm._v(_vm._s(data.item.emailAddress || 'N/A'))])], 1)]), _c('div', [_c('small', {
          staticClass: "align-items-center d-flex flex-nowrap"
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "size": "14",
            "icon": "PhoneCallIcon"
          }
        }), _vm._v(" " + _vm._s(data.item.phoneNumber || 'N/A') + " ")], 1)])];
      }
    }, {
      key: "cell(information)",
      fn: function fn(data) {
        return [_c('b-media', {
          attrs: {
            "vertical-align": "center"
          },
          scopedSlots: _vm._u([{
            key: "aside",
            fn: function fn() {
              return [_c('b-avatar', {
                attrs: {
                  "size": "50",
                  "src": data.item.avatar,
                  "text": _vm.avatarText("".concat(data.item.lastName, " ").concat(data.item.firstName)),
                  "variant": "light-".concat(_vm.resolveEmployeeTypeVariant(data.item.type))
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('div', {
          staticClass: "d-flex flex-column justify-content-center align-items-start"
        }, [_c('h5', {
          staticClass: "mb-0 text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.lastName) + " " + _vm._s(data.item.firstName) + " ")]), _c('small', {
          staticClass: "d-flex justify-content-start align-items-center"
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "size": "14",
            "icon": "AwardIcon"
          }
        }), _c('span', {
          class: "text-".concat(_vm.resolveEmployeeTypeVariant(data.item.type), " font-weight-bold")
        }, [_vm._v(" " + _vm._s(_vm.$te(_vm.resolveEmployeeTypeTitle(data.item.type)) ? _vm.$t(_vm.resolveEmployeeTypeTitle(data.item.type)) : data.item.type) + " ")])], 1), _c('small', {
          staticClass: "d-flex justify-content-start align-items-center"
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "size": "14",
            "icon": "TagIcon"
          }
        }), _c('small', {}, [_vm._v(_vm._s(_vm.$t(_vm.resolveGender(data.item.gender, 'value', 'label'))) + " ")])], 1)])])];
      }
    }, {
      key: "cell(isActive)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex-center"
        }, [_c('b-form-checkbox', {
          staticClass: "mr-0 mt-50 custom-control-success",
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": "",
            "disabled": data.item.isActive ? !_vm.canAccess('employee.activeEmployee') : !_vm.canAccess('employee.deactiveEmployee')
          },
          nativeOn: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.changeActive(data.item);
            }
          },
          model: {
            value: data.item.isActive,
            callback: function callback($$v) {
              _vm.$set(data.item, "isActive", $$v);
            },
            expression: "data.item.isActive"
          }
        })], 1)];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(data) {
        return [_c('b-media', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-media-aside', {
          staticClass: "mr-1"
        }, [_c('b-avatar', {
          attrs: {
            "rounded": "",
            "variant": "light-info",
            "size": "34"
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ClockIcon",
            "size": "18"
          }
        })], 1)], 1), _c('b-media-body', [_c('h6', {
          staticClass: "mb-0 text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.dateTime(data.item.createdAt)) + " ")]), _c('small', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.createdBy ? "".concat(data.item.createdBy.firstName, " ").concat(data.item.createdBy.lastName) : ''))])])], 1)];
      }
    }, {
      key: "cell(updatedAt)",
      fn: function fn(data) {
        return [_c('b-media', {
          attrs: {
            "no-body": ""
          }
        }, [_c('b-media-aside', {
          staticClass: "mr-1"
        }, [_c('b-avatar', {
          attrs: {
            "rounded": "",
            "variant": "light-info",
            "size": "34"
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon",
            "size": "18"
          }
        })], 1)], 1), _c('b-media-body', [_c('h6', {
          staticClass: "mb-0 text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.dateTime(data.item.updatedAt)) + " ")]), _c('small', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.updatedBy ? "".concat(data.item.updatedBy.firstName, " ").concat(data.item.updatedBy.lastName) : ''))])])], 1)];
      }
    }], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }